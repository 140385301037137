import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import Ctasection from '../../containers/Hosting/Ctasection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import BannerSectionApplication from '../../containers/Hosting/Banner/BannerApplication';
import InfoApplicationSection from '../../containers/Hosting/InfoApplication';
import TopRibbon from '../../containers/Hosting/TopRibbon';

class DocumentVerificationPage extends Component {
  render() {
    const name = 'IDVerification';
    return (
      <ThemeProvider theme={hostingTheme}>
        <ParallaxProvider>
          <SEO
            title="Automate your Data Processing"
            description="Rapid Acceleration Partners' RAPFlow automates structured data extraction from unstructured content. Automate your workflow and raise the bar on efficiency. Book a free demo here."
          />

          <ResetCSS />
          <GlobalStyle />
          <TopRibbon />
          <ContentWrapper className="IDVerificationPage">
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSectionApplication name={name} />
            <InfoApplicationSection name={name} />
            <Ctasection name={name} />
            <Footer />
          </ContentWrapper>
        </ParallaxProvider>
      </ThemeProvider>
    );
  }
}

export default DocumentVerificationPage;
